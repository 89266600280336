import React from 'react';
import useFetch from 'react-fetch-hook';
import yaml from 'yaml';
import os from 'platform-detect/os.mjs';
import _ from 'lodash';
import './App.css';

const App = () => {
  const { macos } = os;
  const arch = macos ? '-mac' : '';
  const DL_PREFIX = `/dist`;
  const noCache = Math.floor(Date.now() / 1000);
  const { data } = useFetch(
    `${DL_PREFIX}/latest${arch}.yml?noCache=${noCache}`,
    {
      formatter: response => response.text()
    }
  );

  let path;
  if (data) {
    if (macos) {
      path = _(yaml.parse(data))
        .get('files')
        .map(file => file.url)
        .find(url => url.endsWith('dmg'));
    } else {
      ({ path } = yaml.parse(data));
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="download-button">
          {path ? (
            <>
              <p>Download</p>
              <p>
                <a href={`${DL_PREFIX}/${path}`}>{path}</a>
              </p>
            </>
          ) : (
            'Loading...'
          )}
        </div>
      </header>
    </div>
  );
};

export default App;
